var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      ((_vm.proposta || {}).Endorsement || {}).InsurancePremiumType == "C"
        ? _c("dados-financeiros", { attrs: { blockInputs: _vm.blockInputs } })
        : [
            _c(
              "vs-alert",
              { staticClass: "h-auto", attrs: { color: "#166aa8" } },
              [
                _c("i", { staticClass: "onpoint-warning icon-font mr-3" }),
                _vm._v(
                  "\n      Proposta de endosso não gerou prêmio a cobrar, prossiga com a emissão.\n    "
                )
              ]
            ),
            _vm.policyHolderBankInfo
              ? _c("revisao-premio", { attrs: { canUpdate: false } })
              : _vm._e()
          ],
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-justify": "end" } },
            [
              _vm.proposta.Endorsement.EndorsementReasonId == 18
                ? _c(
                    "vs-button",
                    {
                      staticClass: "button-default mr-4",
                      attrs: { type: "filled" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.salvarProposta()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v("\n          Salvar Endosso\n        ")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.podeEmitirApolice &&
              _vm.proposta.Endorsement.EndorsementTypeId !== 1
                ? _c(
                    "vs-button",
                    {
                      staticClass: "button-default mr-4",
                      attrs: { type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.imprimirMinuta()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                        _vm._v(" Imprimir minuta ")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.proposta.Endorsement.EndorsementReasonId != 18
                ? _c(
                    "div",
                    [
                      _vm.podeEmitirApolice
                        ? _c(
                            "vs-button",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.comfirmaEmissaoEndossoModal = true
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pl-2" },
                                [_vm._v(" Emitir endosso ")]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "fix-scroll",
          attrs: {
            title: "",
            active: _vm.comfirmaEmissaoEndossoModal,
            id: "confirmacao-modal"
          },
          on: {
            "update:active": function($event) {
              _vm.comfirmaEmissaoEndossoModal = $event
            }
          }
        },
        [
          _c("div", { staticClass: "flex flex-col items-center pt-10" }, [
            _c("i", { staticClass: "onpoint-warning icon-font text-warning" }),
            _c("div", { staticClass: "text-confirmation" }, [
              _c("p", { staticClass: "line-1" }, [
                _vm._v("Tem certeza que deseja prosseguir?")
              ]),
              !_vm.Excelsior
                ? _c(
                    "p",
                    { staticClass: "line-2", staticStyle: { color: "red" } },
                    [
                      _vm._v(
                        "\n          Essa ação não poderá ser desfeita!\n        "
                      )
                    ]
                  )
                : _c("p", { staticClass: "line-2" }, [
                    _vm._v("Essa ação não poderá ser desfeita.")
                  ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "text-right px-6 pb-6" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-8 mr-3",
                  attrs: { color: "grey" },
                  on: {
                    click: function($event) {
                      _vm.comfirmaEmissaoEndossoModal == false
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "mt-8 mr-3",
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.showModalTermosAceite()
                    }
                  }
                },
                [_vm._v("Prosseguir")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "fix-scroll",
          attrs: {
            id: "termos-endosso-modal",
            "button-close-hidden": true,
            title: " ",
            active: _vm.termosAceiteModal
          },
          on: {
            "update:active": function($event) {
              _vm.termosAceiteModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-6" },
            [
              _c("h2", { staticClass: "font-bold text-primary" }, [
                _vm._v("Termo e declaração")
              ]),
              _c("p", { staticClass: "font-bold py-5" }, [
                _vm._v("Leia os termos para solicitação de endosso")
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col py-5 px-3 bg-grey-300 text-justify"
                },
                [_c("span", { domProps: { innerHTML: _vm._s(_vm.HTMLTerm) } })]
              ),
              _c(
                "vs-checkbox",
                {
                  staticClass: "mt-8",
                  model: {
                    value: _vm.termosAceite,
                    callback: function($$v) {
                      _vm.termosAceite = $$v
                    },
                    expression: "termosAceite"
                  }
                },
                [
                  _vm._v(
                    "\n        Declaro que li e estou ciente das condições acima.\n      "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-8 mr-3",
                      attrs: { color: "grey" },
                      on: {
                        click: function($event) {
                          _vm.termosAceiteModal = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !_vm.termosAceite,
                        type: "primary",
                        loading: _vm.loadingButton
                      },
                      on: {
                        click: function($event) {
                          return _vm.emitirApoliceEndosso()
                        }
                      }
                    },
                    [_vm._v("Prosseguir")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }